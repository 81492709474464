import React from 'react'
import InputLabel from 'components/common/InputLabel'
import MenuItem from 'components/common/MenuItem'
import {
  Select as MuiSelect,
  withStyles,
  SelectProps,
  FormControl,
} from '@material-ui/core'
import { gradients } from 'styles/colors'

export const Select = withStyles({
  root: {
    background: gradients.dark,
    borderRadius: '4px 4px 0 0',
    '&focused': {
      borderRadius: '4px 4px 0 0',
    },
  },
  select: {
    color: 'white',
    '&:focus': {
      borderRadius: '4px 4px 0 0',
    },
  },
  icon: {
    color: 'white',
  },
})(MuiSelect)

export type SingleSelectProps = {
  className?: string
  error: boolean
  id: string
  items: string[] | number[]
  label: string
  onChange: SelectProps['onChange']
  value?: string | number
}

const SingleSelect = ({
  error,
  id,
  items,
  label,
  value,
  ...props
}: SingleSelectProps) => {
  return (
    <FormControl variant="filled">
      <InputLabel id={id} color="primary">
        {label}
      </InputLabel>
      <Select
        {...props}
        error={!value && error}
        labelId={id || ''}
        value={value || ''}
      >
        {items.map(item => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SingleSelect
