// eslint-disable-next-line import/named
import { State } from '@hookstate/core'
import React, { useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

type BackdropProps = {
  show: State<boolean> | boolean
  onClose?: () => void
}

let body: HTMLBodyElement | null = null
if (typeof window !== 'undefined') {
  body = window.document.querySelector('body')
}

const useBodyOverflow = (hidden: boolean) => {
  useEffect(() => {
    if (body) {
      if (hidden) body.style.overflowY = 'hidden'
      else body.style.overflowY = 'scroll'
    }
  }, [hidden])
}
const Backdrop: React.FC<BackdropProps> = ({ show, onClose, children }) => {
  const visible = typeof show === 'boolean' ? show : show.get()
  const onCloseHandler = () => {
    if (onClose) onClose()
    else if (typeof show !== 'boolean') {
      show.set(false)
    }
  }
  useBodyOverflow(visible)
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          transition={{ duration: 0.1 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={
            'fixed flex items-center justify-center inset-0 z-50 backdrop-blur-md transition-all'
          }
          onClick={onCloseHandler}
          style={{ zIndex: 1000 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Backdrop
