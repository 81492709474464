import React from 'react'
import {
  Button as MUIButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  withStyles,
} from '@material-ui/core'

import { colors, gradients } from 'styles/colors'

const StyledButton = withStyles({
  root: {
    border: 0,
    color: colors.primary.DEFAULT,
    height: 48,
    '&.Mui-disabled': {
      color: colors.grey.DEFAULT,
    },
  },
  label: {
    fontWeight: 500,
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  contained: {
    background: gradients.primary,
    borderRadius: 100,
    border: 0,
    color: colors.secondary.DEFAULT,
    height: 48,
    '&.Mui-disabled': {
      background: colors.secondary.DEFAULT,
      border: `1px solid ${colors.grey.darker}`,
      color: colors.grey.darker,
    },
  },
  outlined: {
    background: gradients.secondary,
    borderRadius: 100,
    border: `1px solid ${colors.primary.DEFAULT}`,
    color: colors.primary.DEFAULT,
    height: 48,
    '&.Mui-disabled': {
      background: colors.secondary.darkest,
      borderColor: colors.grey.darker,
      color: colors.grey.darker,
    },
    hover: {
      background:
        'linear-gradient(358.85deg, rgba(0, 255, 248, 0.45) -19.44%, rgba(4, 208, 202, 0.9) 99.17%)',
    },
  },
  text: {
    borderRadius: 100,
  },
})(MUIButton)

interface ButtonProps extends MuiButtonProps {
  className?: string
  isLoading?: boolean
  primary?: boolean
  secondary?: boolean
}

const Button = ({
  isLoading = false,
  primary,
  secondary,
  ...props
}: ButtonProps) => {
  const variant = primary ? 'contained' : secondary ? 'outlined' : 'text'

  return (
    <StyledButton {...props} variant={variant}>
      {props.children}
      {isLoading && (
        <CircularProgress
          className="absolute top-1/2 left-1/2"
          color="secondary"
          style={{ marginTop: -18, marginLeft: -18 }}
        />
      )}
    </StyledButton>
  )
}

export default Button
