import { MenuItem as MuiMenuItem, withStyles } from '@material-ui/core'
import { colors } from 'styles/colors'

export default withStyles({
  root: {
    backgroundColor: colors.secondary.darker,
    color: 'white',
    '&:hover': {
      backgroundColor: colors.secondary.DEFAULT,
    },
    '&:focus': {
      backgroundColor: colors.primary.dark,
    },
    '&:active': {
      backgroundColor: colors.primary.darker,
    },
  },
})(MuiMenuItem)
