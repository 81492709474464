import React from 'react'
import { motion } from 'framer-motion'

import { IconButton } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'

import Backdrop from 'components/common/Backdrop'

interface ModalProps {
  className?: string
  isCloseButtonVisible?: boolean
  isVisible: boolean
  onClose: () => void
  children: React.ReactNode
  style?: React.CSSProperties
}

const Modal = ({
  className,
  isCloseButtonVisible = false,
  isVisible,
  onClose,
  children,
  style,
}: ModalProps) => {
  return (
    <Backdrop show={isVisible} onClose={onClose}>
      <motion.div
        transition={{ duration: 0.1 }}
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.9 }}
        className={[
          'relative rounded w-full max-w-2xl h-auto bg-secondary text-white text-left',
          className,
        ].join(' ')}
        style={style}
        onClick={e => e.stopPropagation()}
      >
        {children}
        {isCloseButtonVisible && (
          <div className="absolute top-6 right-4">
            <IconButton onClick={onClose} className="bg-secondary">
              <CancelIcon htmlColor="white" fontSize="large" />
            </IconButton>
          </div>
        )}
      </motion.div>
    </Backdrop>
  )
}

export default Modal
