export type Role =
  | 'Backend Engineer'
  | 'Frontend Engineer'
  | 'Fullstack Engineer'
  | 'ML Engineer'
  | 'Data Scientist'
  | 'Product Manager'
  | 'Product Designer'
  | 'UX Research'
  | 'Marketing'
  | 'Business Development'
  | 'Finance'
  | 'Operations'
  | 'HR'
  | 'Other'

export const roles: Role[] = [
  'Backend Engineer',
  'Frontend Engineer',
  'Fullstack Engineer',
  'ML Engineer',
  'Data Scientist',
  'Product Manager',
  'Product Designer',
  'UX Research',
  'Marketing',
  'Business Development',
  'Finance',
  'Operations',
  'HR',
  'Other',
]

export enum RoleEnum {
  BackendEngineer = 'Backend Engineer',
  FrontendEngineer = 'Frontend Engineer',
  FullstackEngineer = 'Fullstack Engineer',
  MLEngineer = 'ML Engineer',
  DataScientist = 'Data Scientist',
  ProductManager = 'Product Manager',
  ProductDesigner = 'Product Designer',
  UXResearch = 'UX Research',
  Marketing = 'Marketing',
  BusinessDevelopment = 'Business Development',
  Finance = 'Finance',
  Operations = 'Operations',
  HR = 'HR',
  Other = 'Other',
}

export type Seniority = 'Junior' | 'Senior' | 'Lead'

export const seniorities: Seniority[] = ['Junior', 'Senior', 'Lead']

type EuLocation =
  | 'Switzerland'
  | 'Germany'
  | 'UK'
  | 'Portugal'
  | 'Spain'
  | 'Poland'
  | 'Hungary'
  | 'Romania'
  | 'Serbia'

export type Location = EuLocation | 'USA'

export const locations: Location[] = [
  'USA',
  'Switzerland',
  'Germany',
  'UK',
  'Portugal',
  'Spain',
  'Poland',
  'Hungary',
  'Romania',
  'Serbia',
]

export type Position = {
  id: number
  role?: Role
  location: Location[]
  otherRole?: string
  seniority?: Seniority
  description?: string
}

export type Team = Position[]

export type Contact = {
  companyName: string
  email: string
  comment: string
}
