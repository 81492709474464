import { breakpointValues } from 'styles/breakpoints'
import useWindowSize from 'hooks/useWindowSize'

export default function useBreakpoints() {
  const windowSize = useWindowSize()

  const isMobileScreen = windowSize?.width
    ? windowSize.width < breakpointValues.md
    : false

  const isLargeScreen = windowSize?.width
    ? windowSize.width < breakpointValues.lg
    : false

  return { isMobileScreen, isLargeScreen }
}
