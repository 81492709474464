import React from 'react'
import {
  InputLabel as MUIInputLabel,
  InputLabelProps,
  withStyles,
} from '@material-ui/core'
import { colors } from 'styles/colors'

const StyledInputLabel = withStyles({
  root: {
    color: colors.primary.DEFAULT,
  },
})(MUIInputLabel)

const InputLabel = (props: InputLabelProps) => {
  return (
    <StyledInputLabel className="text-subtitle" {...props}>
      {props.children}
    </StyledInputLabel>
  )
}

export default InputLabel
