export enum RequestStatus {
  INPUT = 'INPUT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  DONE = 'DONE',
}

export enum ConfiguratorStep {
  CREATE,
  REVIEW,
  SUBMIT,
}
