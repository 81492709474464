// eslint-disable-next-line import/named
import { createState, Downgraded, none, State } from '@hookstate/core'

import { ConfiguratorStep, RequestStatus } from 'types/component/configurator'
import { RolesStats } from 'types/rolesStats'
import { Contact, Position } from 'types/team'

import { generateId } from 'utils/id'

interface ConfiguratorStore {
  contactForm: Contact
  currentStep: ConfiguratorStep
  currentPositionId: number | null
  isError: boolean
  requestStatus: RequestStatus
  rolesStats: RolesStats | null
  team: Position[]
}

const initialState: ConfiguratorStore = {
  contactForm: {
    companyName: '',
    email: '',
    comment: '',
  },
  currentStep: ConfiguratorStep.CREATE,
  currentPositionId: 0,
  isError: false,
  requestStatus: RequestStatus.INPUT,
  rolesStats: null,
  team: [
    {
      id: generateId.next().value as number,
      location: [],
    },
  ],
}

// Export store

const configuratorStore = createState<ConfiguratorStore>(initialState)

export default configuratorStore

// Actions

export const addPosition = (position: State<Position>, quantity = 1) => {
  const rawPosition = position.attach(Downgraded).get()

  configuratorStore.team.merge(
    [...new Array(quantity)].map(() => ({
      ...rawPosition,
      id: generateId.next().value as number,
    }))
  )
  configuratorStore.currentPositionId.set(
    quantity === 1
      ? configuratorStore.team[configuratorStore.team.length - 1].id.get()
      : null
  )
}

export const removePosition = (position: State<Position>) => {
  if (configuratorStore.team.length > 1) position.set(none)
  configuratorStore.currentPositionId.set(null)
}

export const moveStepForward = () => {
  const nextStep = configuratorStore.currentStep.get() + 1

  configuratorStore.currentStep.set(nextStep)
}

export const moveStepBackward = () => {
  const previousStep = configuratorStore.currentStep.get() - 1

  if (previousStep < 0) {
    return
  }

  configuratorStore.currentStep.set(previousStep)
}

export const resetStore = () => {
  configuratorStore.set(initialState)
}
